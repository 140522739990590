





































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import PigeonLogo from '@/components/PigeonLogo.vue';
import {Invite} from '@/domain/model/invite';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import GpNotification from '@/components/custom/Notification.vue';
import TextFieldEmail from '@/components/profile/privacy-security/TextFieldEmail.vue';
import TagManager from '@/mixins/TagManager';
import {businessStore} from '@/store/modules/business/businessStore';
import BusinessStoreBase from '@/components/BusinessStoreBase.vue';
import {auth} from '@/plugins/firebase.init';
import {fetchSignInMethodsForEmail} from 'firebase/auth';
import {applicationStore} from '@/store/modules/application';

@Component({
  name: 'invite-members',
  components: {TextFieldEmail, AvatarWithStatus, PigeonLogo, GpNotification},
  mixins: [BusinessStoreBase]
})
export default class InviteMembers extends mixins(Notifications, TagManager) {
  @Prop() value!: boolean;

  role: any;
  doubleEmail: any = {};
  existsEmail: string[] = [];
  validForm: boolean = false;
  loading: boolean = false;

  roles: any[] = [
    {name: 'Associate', value: 1},
    {name: 'Admin', value: 3}
  ];

  invites: Invite[] = [
    new Invite(0, '', '', 'signUp'),
  ];  

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get allowAddInvite() {
    return this.invites.length < 8;
  }

 
  @Watch('invites', {deep: true})
  onChangeEmails(after) {
    this.existsEmail = []
    this.doubleEmailCounter(after)
    this.checkValidatedForm();
  }

  doubleEmailCounter(items) {
    this.doubleEmail = {}
    items.forEach(item => {
      const email = item.email
      if (email) {
        if (this.doubleEmail.hasOwnProperty(email)) {
          this.doubleEmail[email] = this.doubleEmail[email] + 1
        } else {
          this.doubleEmail[email] = 1
        }
      }
    })
  }

  validateDoubleEmail(v) {
    return !v || this.doubleEmail[v] <= 1 || 'Double email'
  }

  onAddInviteRow() {    
    this.invites.push(new Invite(this.invites.length, '', '', 'signUp'));
  }

  onRemoveInviteRow(item, event) {
    this.invites.splice(this.invites.findIndex((invite) => invite.index === item.index), 1);

    // Remove focus after click
    if (event && event.currentTarget) {
      event.currentTarget.blur();
    }

    // Re-validate
    this.$nextTick(() => {
      this.checkValidatedForm();
    });
  }

  skipInvites() {
    this.redirectToHowToUse();
  }

  redirectToHowToUse() {
    this.$router.push({name: 'how-to-use', params: {}});
  }

  invitationSent() {    
    this.showSuccess('Invites have been sent');
    setTimeout(() => {
      this.redirectToHowToUse();
    }, 3000);
  }

  invitationFailed() {
    this.showIssue('Failed re-invite user');
  }

  async onSendInvites() {    
    const form: any = this.$refs.formInvites;

    if (form.validate()) {
      this.loading = true;
      this.existsEmail = []
            
      for (const invite of this.invites) {
        const providers = await fetchSignInMethodsForEmail(auth, invite.email)
        console.log('providers: ' + providers.length)
        if (providers.length) {
          this.existsEmail.push(invite.email)
          this.showIssue(`Email ${invite.email} already exists`)
          console.log('Email already exists: ')
        }
      }
      if (this.existsEmail.length === 0) {
        console.log('before inviteUsers: ')
        const ok = await businessStore.inviteUsers(this.invites);
        console.log('after inviteUsers: ' + ok)
        this.show = !ok;
        if(ok) {
          this.invitationSent()
        }else {
          this.invitationSent()
        }
      }else{
        this.loading = false;
      }
    }
  }

  checkValidatedForm() {    
    const form: any = this.$refs.formInvites
    if (form) {
      this.validForm = form.validate()
    }       
  }
  
  beforeDestroy() {
    document.body.removeAttribute('style');
  }

  mounted() {    
    document.body.setAttribute('style', 'width: auto; min-width: 0;');
    applicationStore.loadBusiness()  
  }
  
}
