







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    name: 'pigeon-logo'
})
export default class PigeonLogo extends Vue {
    @Prop({default: 90}) width;
    @Prop({default: 70}) height;

    get logoImage() {
        return 'assets/pigeon_logo.png';
    }
}
